@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial, Helvetica, sans-serif;
}

@layer components {
  .title {
    @apply font-bold text-black text-xl max-sm:text-base max-sm:font-semibold;
  }

  .empty-msg {
    @apply text-neutral-500 dark:text-neutral-200 text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-sm:text-xs sm:text-sm capitalize;
  }

  .table-container {
    @apply w-full h-fit dark:bg-primary-950 rounded-lg table-auto;
  }

  .table-head {
    @apply left-0 z-[5] sticky bg-neutral-200 dark:bg-primary-900 rounded-b-lg top-0;
  }

  .appbar {
    @apply bg-transparent py-4 bg-neutral-50 dark:bg-primary-950 dark:border-primary-950 max-sm:rounded-none sm:!rounded-xl z-10 sticky top-0 mb-4 border border-neutral-100 left-0;
  }

  .table-head-cell {
    @apply text-xs sm:text-sm px-2 text-left capitalize text-neutral-600 dark:text-neutral-200 py-3;
  }

  .add-btn {
    @apply bg-neutral-200 dark:bg-primary-900 dark:text-neutral-200 text-xs text-nowrap h-fit sm:text-sm w-fit hover:text-white text-black;
  }

  .modal-btn {
    @apply capitalize sm:max-w-36 max-sm:min-w-16 text-sm w-full max-sm:max-w-28;
  }

  .modal-header {
    @apply p-4 capitalize max-sm:text-sm sm:text-lg;
  }

  .table-tab div {
    @apply h-full w-full;
  }
}

.sidebar div {
  background-color: transparent;
}

.scale-in {
  animation: scalein 0.3s ease;
}

@keyframes scalein {
  from {
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: translate(0) scale(1);
  }
}

@keyframes bottomTotop {
  from {
    transform: translateY(50%);
    opacity: 0;
    z-index: -1;
  }

  to {
    opacity: 1;
    z-index: 2;
    transform: translateY(0%);
  }
}

.bottomTotop {
  animation: bottomTotop 0.3s ease forwards;
}

.truncated {
  max-inline-size: -webkit-fill-available;
}

.text-clamp {
  width: 100%;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #fafafa81;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(228, 228, 228);
  border: 1px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #eee;
}

.dashboard {
  max-height: calc(100vh - 70px);
}

.mobile-dashboard {
  max-height: calc(100vh - 147px);
}